@import '../../abstracts/variables.sass';

.recommendationPackages {
  width: 100%;

  &--support {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    h3 {
      text-align: center;
      font-size: 1.7rem;
      font-weight: 600;
      color: var(--main-text-color);
      margin-bottom: 1rem;
      margin-bottom: 1rem;
    }
    svg {
      fill: #33ce57;
      height: 5rem;
      width: 5rem;
    }
  }

  &__titles {
    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
      margin-bottom: 0;
    }

    p {
      font-size: 1.6rem;
      color: $main-light-gray-font-color-5;
      font-family: var(--main-font-primary) !important;
      margin-bottom: 2rem;
    }
  }

  &__banner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 2rem;
    border-radius: 0.8rem;
    background-color: #ffdd33;
    margin-bottom: 2rem;
    gap: 2rem;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &--left {
      h3,
      p,
      span {
        font-family: var(--main-font-primary) !important;
      }

      h3 {
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 600;
        color: rgb(22, 22, 22);
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 1.4rem;
        line-height: 1.65rem;
        color: rgb(65, 65, 65);
        margin-bottom: 0 !important;
      }
    }
    &--right {
      border: 1px solid rgb(22, 22, 22);
      border-radius: 3rem;
      padding: 0.8rem 2rem;
      cursor: pointer;
      background-color: #ffdd33;
      transition: $normal-transition;

      &:hover {
        background-color: #f7d633dc;
      }

      span {
        font-size: 1.6rem;
        line-height: 1.6rem;
        font-weight: 600;
        color: rgb(22, 22, 22);
        white-space: nowrap;
      }
    }
  }

  &__selection {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &--text {
      font-size: 1.5rem;
      font-weight: 500;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
      margin-right: 1rem;
    }

    &--toggle {
      margin-right: 1rem;
      position: relative;
      display: inline-block;

      &-input {
        display: none;
      }
      &-label {
        display: block;
        width: 4rem;
        height: 2.3rem;
        background-color: rgb(41, 41, 41);
        border-radius: 9rem;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &::after {
          content: '';
          position: absolute;
          top: 0.3rem;
          left: 0.35rem;
          width: 1.75rem;
          height: 1.75rem;
          background-color: var(--main-btn-text-color);
          border-radius: 50%;
          transition: left 0.3s ease;
        }
      }

      &-input:checked + &-label::after {
        left: 2rem;
      }
    }

    &--info {
      font-size: 1.1rem;
      color: #706114;
      font-family: var(--main-font-primary) !important;
      padding: 0.2rem 1rem;
      background-color: #ffdd33ab;
      border: 1px solid #ffdd33;
      border-radius: 2rem;
      font-weight: 500;
    }
  }

  &__items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 3rem;

    @media only screen and (max-width: 900px) {
      flex-direction: column;
      gap: 3rem;
    }
  }
}

.recommendationPackageItem {
  width: 30rem;
  min-height: 40rem;
  border: 1px solid $main-light-gray-font-color-2;
  padding: 2rem;
  border-radius: 0.8rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  transition: $normal-transition;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  @media only screen and (max-width: 1000px) {
    max-width: 30rem;
    min-width: 25rem;
  }

  &:hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  }

  &[data-is-most-popular='true'] {
    border: 2px solid #ffdd33;
    height: 42rem;

    .recommendationPackageItem__button {
      .mainSquareButton {
        background-color: #ffdd33;
        color: rgb(22, 22, 22);
        &:hover {
          background-color: #ffdd33dc;
        }
      }
    }
  }

  &__badge {
    position: absolute;
    top: -1.5rem;
    right: -2rem;
    padding: 0.5rem 1rem;
    background-color: #ffdd33;
    color: rgb(22, 22, 22);
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 2rem;
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    color: var(--main-text-color);
    font-family: var(--main-font-primary) !important;
    margin-bottom: 3rem;
  }
  &__description {
    font-size: 1.4rem;
    color: rgb(133, 133, 133);
    font-family: var(--main-font-primary) !important;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  &__prices {
    &--monthly {
      display: flex;
      font-size: 1.6rem;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
      margin-bottom: 0.5rem;
      align-items: end;

      h2 {
        font-size: 2.8rem;
        font-weight: 700;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        margin-bottom: 0rem;
      }

      p {
        font-size: 1.6rem;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        margin-bottom: 0.7rem;
        font-weight: 500;
      }
    }

    &--annual {
      display: flex;
      font-size: 1.6rem;
      color: var(--main-text-color);
      font-family: var(--main-font-primary) !important;
      margin-bottom: 0.5rem;
      align-items: end;

      h3 {
        font-size: 2.8rem;
        font-weight: 700;
        color: rgb(133, 133, 133);
        font-family: var(--main-font-primary) !important;
        margin-bottom: 0rem;
        margin-right: 1rem;
        text-decoration: line-through;
      }
      h2 {
        font-size: 2.8rem;
        font-weight: 700;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        margin-bottom: 0rem;
      }

      p {
        font-size: 1.6rem;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        margin-bottom: 0.7rem;
        font-weight: 500;
      }
    }
  }

  &__features {
    &--item {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      gap: 0.5rem;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 1.5rem;
          height: 1.5rem;
          fill: #22c55e;
        }
      }

      &-text {
        font-size: 1.4rem;
        color: var(--main-text-color);
        font-family: var(--main-font-primary) !important;
        // font-weight: 500;
      }
    }
  }

  &__button {
    width: 100%;
    margin-top: 0.5rem;

    .mainSquareButton {
      width: 100%;
      height: 4rem;
      font-size: 1.5rem;
      transition: $normal-transition;
      background-color: rgb(26, 26, 26);
      &:hover {
        background-color: rgb(36, 36, 36);
      }
    }
  }
}
